* {
	margin: 0;
	padding: 0;
}

html,
body,
#app,
#app > div {
	margin: 0;
	flex: 1;
	height: 100%;
}
